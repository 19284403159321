import classNames from 'classnames';
import styles from './Confirmation.module.scss';
import ButtonSH, { ButtonStatusType } from 'SomeoneHealth/components/ButtonSH/ButtonSH';
import { useGetClientProfileDetails } from 'redux/endpoints/clinicianProfileServices/getClientDetails';
import { useGetReservedAppointmentByIdQuery } from 'redux/endpoints/scheduleServices/reservedAppointment';
import { useReserveGPAppointmentData } from 'utils/hooks/EngageReserved/reservedAppointment';
import { useEffect, useRef, useState } from 'react';
import { useSomeoneHealthToken } from 'SomeoneHealth/utils/hooks/useSomeoneHealthToken';
import { putProcessWithPaymentMethod } from 'utils/http/appointment';
import { useSomeoneHealthRoutesGenerator } from 'SomeoneHealth/utils/Path/SomeoneHealthRoutesGenerator';
import { Link, useNavigate } from 'react-router-dom';
import { Skeleton, message } from 'antd';
import { fetchClaimingReservation } from 'utils/hooks/EngageReserved/useClaimReservationWithoutCheckoutSession';
import SummaryItem from 'SomeoneHealth/pages/BookingConfirmation/components/Summary/components/SummaryItem/SummaryItem';
import { toWord } from 'utils/generateCamelCase';
import { useGetAccountClinicianDetailsByIdQuery } from 'redux/endpoints/clinicianProfileServices/getClinicianDetails';
import { AppointmentSlot } from 'utils/hooks/appointment';
import { useTimeZone } from 'utils/hooks/useTimeZone';
import {
  defaultClinicianTimezone,
  SOMEONE_HEALTH_TIME_ZONE_LIST,
  someoneHealthTimeZoneLocalStorage
} from 'utils/constants/timeZone';
import { massageTime } from 'SomeoneHealth/utils/hooks/appointment';
import Timer from '../Timer/Timer';
import PaymentPolicy from 'SomeoneHealth/components/PaymentPolicy/PaymentPolicy';
import { useGetPracticeInfoQuery } from 'redux/endpoints/clinicianProfileServices/accounts';
import { someoneHealthEnvironment } from 'SomeoneHealth/utils/SomeoneHealthEnv/SomeoneHealthEnv';

const Confirmation = () => {
  const navigate = useNavigate();
  const { token } = useSomeoneHealthToken();
  const { GP } = useSomeoneHealthRoutesGenerator();
  const { CancellationFee } = someoneHealthEnvironment();
  const clientTimeZone = useTimeZone(SOMEONE_HEALTH_TIME_ZONE_LIST, someoneHealthTimeZoneLocalStorage);
  const { reserveId, accountId, clinicianId } = useReserveGPAppointmentData();
  const { data: practiceInfoData } = useGetPracticeInfoQuery({ slugUrlOrAccountId: accountId! }, { skip: !accountId });
  const practiceTimeZone = practiceInfoData?.accountSettings?.timezone || defaultClinicianTimezone;

  const [mobileTimer, setMobileTimer] = useState<boolean>(false);
  const [confirmBookingStatus, setConfirmBookingStatus] = useState<ButtonStatusType>('');
  const [policyAccepted, setPolicyAccepted] = useState(false);
  const [processingPolicyAccepted, setProcessingPolicyAccepted] = useState(false);
  const [reservedAppointment, setReservedAppointment] = useState<AppointmentSlot>();

  const bookingContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (bookingContentRef.current !== null) {
        const { width } = bookingContentRef.current.getBoundingClientRect();
        setMobileTimer(width <= 992);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { clientProfileData } = useGetClientProfileDetails();
  const {
    data: reservedAppointmentsData,
    isLoading: isReservedAppointmentsLoading,
    isFetching: isReservedAppointmentsFetching
  } = useGetReservedAppointmentByIdQuery(
    { accountId: accountId || '', reserveId: reserveId || '' },
    { skip: !accountId || !reserveId }
  );

  const {
    data: gpDetails,
    isLoading: isGPDetailsLoading,
    isFetching: isGPDetailsFetching
  } = useGetAccountClinicianDetailsByIdQuery({ clinicianId: clinicianId || '' }, { skip: !clinicianId });

  useEffect(() => {
    if (
      !isReservedAppointmentsLoading &&
      !isReservedAppointmentsFetching &&
      reservedAppointmentsData &&
      reservedAppointmentsData.length
    ) {
      const formattedData: AppointmentSlot = {
        ...reservedAppointmentsData[0],
        startTime: massageTime(
          reservedAppointmentsData[0].startTime,
          reservedAppointmentsData[0].date || '',
          clientTimeZone,
          practiceTimeZone
        ),
        endTime: massageTime(
          reservedAppointmentsData[0].endTime,
          reservedAppointmentsData[0].date || '',
          clientTimeZone,
          practiceTimeZone
        )
      };
      setReservedAppointment(formattedData);
    }
  }, [
    reservedAppointmentsData,
    isReservedAppointmentsLoading,
    isReservedAppointmentsFetching,
    clientTimeZone,
    practiceTimeZone
  ]);

  const currentUrl = window.location.origin;

  const onSubmit = async () => {
    if (reserveId && accountId && token) {
      setConfirmBookingStatus('active');
      try {
        // Claim the reservation
        await fetchClaimingReservation(reserveId, accountId, token);

        if (!clientProfileData?.hasSavedCard) {
          // Show Add payment details page
          navigate(GP.ADD_PAYMENT);
        } else {
          // Confirm the appointment
          const res = await putProcessWithPaymentMethod(
            accountId,
            reserveId,
            { cancelUrl: `${currentUrl}${GP.CONFIRM_BOOKING}`, returnUrl: `${currentUrl}${GP.COMPLETE}` },
            token
          );

          if (res.statusCode === 200) {
            setConfirmBookingStatus('finished');
            const responseData = await res.json();
            if (responseData.checkoutUrl) {
              window.location.href = responseData.checkoutUrl;
            }
          } else if (res.statusCode === 204) {
            setConfirmBookingStatus('finished');
            navigate(GP.COMPLETE);
          }
        }
        setConfirmBookingStatus('');
      } catch (ex) {
        console.error(ex);
        message.error('Something went wrong while trying to submit your appointment request. Please try again');
        setConfirmBookingStatus('');
      }
    } else {
      console.error(`Something is missing: reserveId: ${reserveId}, accountId: ${accountId}, token: ${token}`);
      message.error('Something went wrong while trying to submit your appointment request. Please try again.');
    }
  };

  return clientProfileData ? (
    <div ref={bookingContentRef} className={classNames(styles.container, 'someone-health-theme')}>
      <div className={styles.headerContent}>
        <Link to={GP.BASE} className={styles.changeAppointment}>
          <i className={classNames('material-icons', styles.icon)}>arrow_back_ios</i>
          Change appointment details
        </Link>
        <div className={styles.greet}>
          Thanks <div className={styles.name}>{clientProfileData.firstName}</div>
        </div>
        <div className={styles.greetDesc}>Here is the summary of the appointments being held for you:</div>
      </div>
      <div className={styles.content}>
        <div className={styles.leftContent}>
          {isReservedAppointmentsLoading ||
          isReservedAppointmentsFetching ||
          isGPDetailsLoading ||
          isGPDetailsFetching ? (
            <Skeleton active />
          ) : (
            reservedAppointment && (
              <SummaryItem
                index={1}
                bookingName={reservedAppointment.sessionTypeName || ''}
                name={gpDetails?.name || ''}
                startTime={reservedAppointment.startTime}
                endTime={reservedAppointment.endTime}
                date={reservedAppointment?.date || ''}
                deliveryMode={reservedAppointment.deliveryType ? toWord(reservedAppointment.deliveryType) : ''}
                showTopSeparation
                isLast
                showBottomSeparation
              />
            )
          )}
          <div className={styles.importantInfo}>
            <span className={styles.info}>If you don’t attend your appointment you will be charged:</span>
            <div className={styles.chargesWrapper}>
              <span className={styles.info}>TOTAL</span>
              <span className={styles.totalCharges}>${CancellationFee}</span>
            </div>
          </div>
          <div className={styles.paymentPolicyWrapper}>
            <PaymentPolicy
              policyAccepted={policyAccepted}
              onSetPolicyAccepted={setPolicyAccepted}
              processingPolicyAccepted={processingPolicyAccepted}
              onSetProcessingPolicyAccepted={setProcessingPolicyAccepted}
            />
          </div>
          <ButtonSH
            status={confirmBookingStatus}
            onClick={onSubmit}
            disabled={!policyAccepted || !processingPolicyAccepted}
            className={styles.submitButton}
          >
            {!clientProfileData?.hasSavedCard
              ? 'Add card details to confirm your booking'
              : 'Confirm Appointment Booking'}
          </ButtonSH>
        </div>
        <div className={styles.rightContent}>
          <div className={styles.notice}>
            <Timer
              isReservedAppointmentFetching={isReservedAppointmentsLoading || isReservedAppointmentsFetching}
              reservedAppointments={reservedAppointment ? [reservedAppointment] : []}
              onlyText={mobileTimer}
            />
            <div className={styles.noticeMsg}>
              <div>To confirm these appointments you need to complete registration and payment within 15 minutes</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default Confirmation;
